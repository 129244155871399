@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");

/* body,
* {
  font-family: "Tajawal", sans-serif !important;
  direction: rtl;
}
.Lalezar {
  font-family: Lalezar !important;
}
@font-face {
  font-family: "DIN Next LT Arabic";
  src: url("./constants/DINNextLTArabic-Regular-3.ttf");
} */

@font-face {
  font-family: "ArbFONTS";
  src: url("./constants/ArbFONTS-DINNextLTArabic-Regular-3.ttf");
}

/* Apply ArbFONTS globally */
body,
* {
  font-family: "ArbFONTS", sans-serif !important;
  direction: rtl;
}
.popup-font * {
  font-family: "ArbFONTS" !important;
}
@layer utilities {
  .gradient-nav-bg {
    background-image: linear-gradient(
      180deg,
      #e4fdff 23.23%,
      rgba(228, 253, 255, 0) 100%
    );
  }
  .gradient-sidebar-bg {
    background: linear-gradient(
      180deg,
      #e4fdff 23.23%,
      rgba(228, 253, 255, 0) 100%
    );
  }
  .search-shadow {
    box-shadow: 0px 3px 4px 0px #00000008;
  }
  .home-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ar .home-banner {
    background-image: url("/public/home-images.jpg");
  }
  .phone-sh {
    box-shadow: 0px 15px 15px 0px #00000026;
  }
  .tabs-gridiant {
    background: linear-gradient(
      180deg,
      #e4fdff 23.23%,
      rgba(228, 253, 255, 0) 100%
    );
  }
  .active-btn-sh {
    box-shadow: 0px 15px 15px 0px #60606040;
  }
  .chat-sh {
    box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
  }
}
/* width */
.bar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.bar::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.bar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #cccccc;
}

/* Handle on hover */
.bar::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2;
}
/* login start */
.swiper-pagination-bullets {
  bottom: 13px !important;
  right: 0 !important;
  width: fit-content !important;
}
.swiper-pagination-bullet {
  background-color: #b7cffc !important;
  width: 8.67px !important;
  height: 8px !important;
  transition: 300ms;
}
.swiper-pagination-bullet-active {
  background-color: #006cfd !important;
  width: 52.03px !important;
  height: 8px !important;
  border-radius: 4px !important;
}
.login-form .ant-form-item {
  margin-bottom: 20px;
}
.login-form .ant-form-item-explain-error {
  margin: 5px 0 12px;
  font-weight: 500;
  font-family: Tajawal;
}
.checkbox span {
  color: #161313;
  font-family: Tajawal;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}
.login-form .ant-btn {
  width: 100%;
  background-color: #006cfd;
  height: 45px;
  padding: 13px 24px 13px 24px;
  border-radius: 8px;
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  margin-top: 22px;
}
.login-form .ant-form-item:first-child .ant-input,
.login-form .ant-input-affix-wrapper {
  height: 55px;
  border-radius: 8px;
  border: 1px solid #192252 !important;
  background-color: white !important;
}
.login-form .ant-input::placeholder {
  font-size: 16px;
  line-height: 19.2px;
  color: #161313;
  font-family: Tajawal;
  opacity: 0.6;
}
/* login end */
/* ant table start */
.ant-table-thead tr th {
  color: #a1a5b7 !important;
  border: 0 !important;
  background-color: transparent !important;
}
.ant-table-thead tr th::before {
  display: none;
}
.latest-user .ant-table-tbody tr:last-child td {
  border: 0 !important;
}
.table .ant-table-tbody tr {
  border-radius: 7px;
  border: 1px solid var(--Gray-Gray-200, #f1f1f2) !important;
  background: var(--white-dark-white, #fff);

  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.table .ant-table table {
  border-spacing: 0 26px;
}
.contranct-table .ant-table table {
  border-spacing: 0 24px;
}
.contranct-table .ant-table-tbody tr {
  height: 101px;
}
@media (max-width: 1024px) {
  .table .ant-table-content {
    width: 1000px;
  }
  .latest-user .ant-table-content {
    width: 700px;
  }
}
/* ant table end*/
/* ant select start */
.rounded-select .ant-select-selector {
  border-radius: 40px !important;
  border-color: 1px solid #efebf2 !important;
}
.ant-select-selection-item {
  font-family: Tajawal !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
/* ant select end */
/* ant modal start */
.ant-modal-mask {
  z-index: 1000 !important;
}
@media (min-width: 601px) {
  .ant-modal {
    width: 595px !important;
  }
}
/* ant modal end */
/* ant popover start */
.ant-popover {
  z-index: 100;
}
/* ant popover end */
@media (max-height: 601px) {
  .mySwiper img {
    width: 230px !important;
  }
  .swiper-pagination-bullets {
    bottom: 8px !important;
  }
}
@media (min-height: 800px) {
  .swiper-pagination-bullets {
    bottom: 6% !important;
  }
}
@media (min-height: 900px) {
  .swiper-pagination-bullets {
    bottom: 7% !important;
  }
}
@media (min-height: 1000px) {
  .swiper-pagination-bullets {
    bottom: 12% !important;
  }
}
.add-unit .ant-select-selector {
  height: 61.49px !important;
}
.add-unit .ant-input {
  height: 61.49px;
}
.add-unit .ant-select-arrow {
  margin-top: 8px !important;
}
.ant-form-item-label {
  display: block !important;
}
.text-area .ant-form-item-control-input-content .ant-input {
  height: auto;
  resize: vertical !important;
}
.form-item-m-0 .ant-form-item {
  margin-bottom: 0;
}
.ant-image-mask,
.ant-image-preview-img {
  border-radius: 12px;
}
.ant-image-preview-operations {
  background-color: #383838c0 !important;
}
.ant-select {
  width: 100%;
}
.ant-radio-button-wrapper {
  border: none !important;
}
.ant-picker-outlined {
  width: 100% !important;
}
.ant-picker-input {
  height: 52px !important;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px !important;
}

.map-container {
  height: 249px;
  width: 70%;
}
.ant-input-affix-wrapper {
  height: 61.49px !important;
}
@media (max-width: 600px) {
  .map-container {
    width: 100%;
  }
}
.custom-input input:focus {
  box-shadow: none;
}

.ant-switch-checked {
  background-color: #37e372 !important;
}

.ant-switch-disabled {
  background: #c5c5c5 !important;
}

.ant-switch-handle {
  margin-top: 6.6px;
  width: 28px !important;
  height: 28px !important;
}
.ant-switch-checked .ant-switch-handle {
  margin-top: 8px;
}
.ant-switch-handle::before {
  border-radius: 50% !important;
}
.ant-switch .ant-switch-handle {
  inset-inline-start: 8px !important;
}
.ant-switch-checked .ant-switch-handle {
  inset-inline-start: 80px !important;
}
.ant-switch-inner {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}
.ant-switch-inner span {
  margin-top: 12px;
}
.small-switch .ant-switch-handle {
  width: 22px !important;
  height: 22px !important;
  margin-top: 3px !important;
}
.small-switch .ant-switch-checked .ant-switch-handle {
  inset-inline-start: 32.5px !important;
}
.ant-color-picker-color-block {
  width: 100% !important;
}
.ant-checkbox .ant-checkbox-inner:after {
  top: 50% !important;
  display: list-item;
}
.st-upload .ant-upload-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding-inline-start: 20px;
}
.st-upload .ant-upload-list,
.st-upload .ant-upload-icon {
  margin-top: 3px;
}
.en .st-arrow {
  rotate: -180deg;
}

.ant-popover-inner {
  margin-inline-start: 14px;
  width: 150px;
}
.ant-pagination {
  direction: initial;
}
.fc-today-button {
  margin-right: 0.75em !important;
}
.fc-button-group {
  direction: initial;
}
.fc-dayGridMonth-view {
  direction: ltr !important;
}

.en .table-text {
  text-align: left;
}
.rst__moveHandle {
  border-radius: 0 6px 6px 0 !important;
  width: 70px !important;
  height: 45px !important;
  background: #e6f1ff url("../public/icons/bars.svg") no-repeat center !important;
  border: 0 !important;
}
.en .rst__moveHandle {
  border-radius: 6px 0px 0px 6px !important;
}
.rst__rowTitle {
  color: #2f2d38 !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}
.rst__rowSubtitle {
  color: #2f2d38 !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}
.rst__node {
  width: 367px !important;
}
.rst__rowContents {
  border-radius: 7px 0px 0px 7px !important;
  border: 1px solid #f1f1f2 !important;
  background: #fff !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  height: 45px !important;
  width: 367px !important;
}
.rst__tree,
.rst__virtualScrollOverride {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.rst__tree::-webkit-scrollbar,
.rst__virtualScrollOverride::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.en .rst__rowContents {
  border-radius: 0 7px 7px 0 !important;
}
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-content: center;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  aspect-ratio: 1 / 0.9;
}

.gallery-item .ant-image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.add-unit .ant-select-selection-placeholder {
  color: rgb(0 0 0 / 0.6) !important;
}
.add-unit .ant-checkbox-inner {
  background-image: url("../public/icons/un-check.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent !important;
  border-color: transparent !important;
}

.add-unit .ant-checkbox-checked .ant-checkbox-inner {
  background-image: url("../public/icons/check.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent !important;
  border-color: transparent !important ;
}

.add-unit .ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}

.add-unit .ant-checkbox-inner > * {
  background-color: transparent !important;
  border: none !important;
}
.add-unit .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.rst__moveHandle {
  cursor: not-allowed !important;
}
.droppable-images .ant-upload-list-item-container {
  display: none !important;
}
.droppable-images .ant-image .ant-image-img {
  width: 102px !important;
  height: 102px !important;
  z-index: 1;
}
.bool .ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input-group-addon {
  width: 50px !important;
}
.ant-input-group-addon .ant-select-selector .ant-select-selection-item {
  right: -10px;
}
.en .ant-input-group-addon .ant-select-selector .ant-select-selection-item {
  right: auto;
  left: 10px;
}
.ant-select-selection-item .flag {
  width: 38px !important;
}
.phone-container * {
  direction: ltr !important;
}
.phone-container .ant-phone-input-wrapper .ant-input-group-addon * {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.suffix-height {
  height: 33px !important;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0; /* Ensure the image stays within the viewport */
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}

/* Ensure proper dimensions for the image itself */
.lg-object {
  max-width: 100%; /* Prevent image from overflowing */
  max-height: 100%; /* Ensure it stays within container */
  margin: 0 auto; /* Center the image */
}

.date-picker {
  width: 200px !important;
}
.filter-item {
  width: 200px !important;
  height: 44px !important;
  margin-bottom: 0px !important;
}
.filter-popup {
  width: 1146px !important;
  padding: 43px 49px !important;
  @media (max-width: 1146px) {
    width: 95% !important;
  }
}
.not-margin {
  margin-bottom: 0px !important;
}
.home .ant-select-selector {
  color: #7e8299;
  background-color: #f1f1f2 !important;
  width: 115px !important;
  font-size: 13px;
}
.sub-info .ant-table-thead {
  display: none;
}
.sub-info .ant-table-cell {
  border: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.sub-info .ant-modal-content {
  height: 380px !important;
  overflow: auto;
}

.sub-info .anticon-close {
  display: none;
}
.fc .fc-button-group {
  gap: 5px;
}
.support .ant-upload-list {
  position: absolute;
  bottom: -25px;
  right: 0;
  width: 100%;
}
.custom-dashed {
  border-style: dashed;
  border-width: 2px;
  border-image: linear-gradient(to right, gray 5px, rgba(255, 255, 255, 0) 10px)
    1;
}
.input-otp__field {
  max-width: 100% !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
